import axios from "axios";
import TokenManager from "./TokenManager";
import ApiKeyEnc from "./ApiKeyEnc";

const SWAGGER_SUBCATEGORY_URL =process.env.REACT_APP_SWAGGER_BASE_URL + "/SubCategories";
const API_KEY = process.env.REACT_APP_API_KEY;

const SubCategoryApi = {
  getAllSubCategories: async () => {
    try {
      const encKey = await ApiKeyEnc.encrypt(API_KEY);
      const response = await axios.get(SWAGGER_SUBCATEGORY_URL, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  getAllSubCategoriesByCatId: async (id) => {
    try {
      const encKey = await ApiKeyEnc.encrypt(API_KEY);
      const response = await axios.get(
        SWAGGER_SUBCATEGORY_URL + "?categoryId=" + id,
        {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
            "x-api-key": encKey,
          },
        }
      );
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  getSubCategoryById: async (id) => {
    try {
      const encKey = await ApiKeyEnc.encrypt(API_KEY);
      const response = await axios.get(SWAGGER_SUBCATEGORY_URL + "/" + id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  addSubCategory: async (payload) => {
    try {
      const encKey = await ApiKeyEnc.encrypt(API_KEY);
      const response = await axios.post(SWAGGER_SUBCATEGORY_URL, payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  updateSubCategory: async (payload) => {
    try {
      const encKey = await ApiKeyEnc.encrypt(API_KEY);
      const response = await axios.patch(SWAGGER_SUBCATEGORY_URL, payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  deleteSubCategory: async (id) => {
    try {
      const encKey = await ApiKeyEnc.encrypt(API_KEY);
      const response = await axios.delete(SWAGGER_SUBCATEGORY_URL + "/" + id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
};
export default SubCategoryApi;
