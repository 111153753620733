import ProductsApi from "Apis/ProductsApi";
import React, { useEffect, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import ProductCard from "./ProductCard";

const ProductsManagement = () => {
  const [serchTerm, setSearchTerm] = useState<string>();
  const [products,setProducts]=useState<any>()

  const getAllProducts= async ()=>{
    const response =await ProductsApi.getAllProducts()
    // console.log(response)
    if(response.success){ 
        setProducts(response.data)
    }
  }

  useEffect(()=>{
    getAllProducts() 
  },[])
  return (
    <div className={`nc-PageSearch`} data-nc-id="PageSearch">
      <div
        className={`nc-HeadBackgroundCommon h-24 2xl:h-28 top-0 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
        data-nc-id="HeadBackgroundCommon"
      />
      <div className="container">
        <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
        <span className="text-center">Manage all products</span>
          <form
            className="relative w-full "
            onSubmit={(e) => e.preventDefault()}
          >
                          
            <label
              htmlFor="search-input "
              className="text-neutral-500 dark:text-neutral-300 "
            >
              <Input
                className="shadow-lg border-0 dark:border "
                id="search-input"
                type="search"
                value={serchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Type product name or id"
                sizeClass="pl-14 py-5 pr-5 md:pl-16"
                rounded="rounded-full"
              />
              <ButtonCircle
                className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                size=" w-11 h-11"
                type="submit"
              >
                <i className="las la-arrow-right text-xl"></i>
              </ButtonCircle>
              <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </label>
          </form>
        </header>
      </div>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>

          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
            {products?.map((item:any) => (
              <ProductCard data={item} key={item.id} />
            ))}
          </div>
 
          {/* PAGINATION */}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-center sm:items-center">
            {/* <Pagination /> */}
            <ButtonPrimary loading>Show me more</ButtonPrimary>
          </div>
        </main>
      </div>



    </div>
  );
};

export default ProductsManagement;
