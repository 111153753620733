import React, { FC, useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthApi from "Apis/AuthApi";
import toast from "react-hot-toast";
import ShippingsApi from "Apis/ShippingsApi";
import Label from "components/Label/Label";
import Select from "shared/Select/Select";

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [formIsValid, setFormIsValid] = useState<any>(false);
  const [loading, setLoading] = useState(false);

  // Form state
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    street: "",
    city: "",
    houseNr: "",
    houseNrExt: "",
    state: "",
    postCode: "",
    country: "NL",
    password: "",
    phoneNumber: "",
  });

  useEffect(() => {
    // Updated validation logic to require all fields to be filled out
    const isValid =
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      formData.street &&
      formData.city &&
      formData.state &&
      formData.postCode &&
      formData.country &&
      formData.houseNr &&
      formData.password.length >= 6 &&
      formData.phoneNumber;

    setFormIsValid(isValid);
  }, [formData]);

  // const confirmAddress=async()=>{
  //   const payload= {
  //     postalCode:formData.postCode,
  //     houseNumber:formData.houseNr,
  //     houseNumberAddition:formData.houseNrExt
  //   }
  //   const response = await ShippingsApi.confirmAddress(payload)
  //   console.log("responsey",response)
  // }

  // useEffect(()=>{
  //   if(formData.postCode && formData.houseNr){
  //     confirmAddress()
  //   }

  // },[formData.postCode,formData.houseNr])

  // Form handlers
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!formIsValid) {
      toast.error(" password must be at least 6 characters.");
      return;
    }

    setLoading(true);
    try {
      const { success, error, data }: any = await AuthApi.register({
        firstName: formData.firstName,
        lastName: formData.lastName,
        middleName: formData.middleName,
        email: formData.email,
        shippingAddress: {
          street: formData.street,
          houseNr: formData.houseNr,
          city: formData.city,
          state: formData.state,
          postCode: formData.postCode,
          country: formData.country,
          houseNrExt: formData.houseNrExt,
        },
        password: formData.password,
        phoneNumber: formData.phoneNumber,
      });

      if (success) {
        setLoading(false);
        toast.success(`Welcome ${formData.firstName} ${formData.lastName}`);
        navigate(from, { replace: true });
      } else {
        setLoading(false);
        toast.error(error?.response?.data?.detail || "Registration failed.");
      }
    } catch (err) {
      setLoading(false);
      toast.error("An unexpected error occurred. Please try again.");
      console.error(err);
    }
  };
console.log(formData)
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up | Ood Blue</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            {/* Personal Information */}

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                First Name
              </span>
              <Input
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                type="text"
                className="mt-1"
                required
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Last Name
              </span>
              <Input
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                type="text"
                className="mt-1"
                required
              />
            </label>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Middle Name
              </span>
              <Input
                name="middleName"
                value={formData.middleName}
                onChange={handleInputChange}
                type="text"
                className="mt-1"
              />
            </label>

            {/* Contact Information */}
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email Address
              </span>
              <Input
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                type="email"
                className="mt-1"
                required
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Phone Number
              </span>
              <Input
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                type="tel"
                className="mt-1"
              />
            </label>

            {/* Address Information */}
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Postal Code
              </span>
              <Input
                name="postCode"
                value={formData.postCode}
                onChange={handleInputChange}
                type="text"
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                House nr
              </span>
              <Input
                name="houseNr"
                value={formData.houseNr}
                onChange={handleInputChange}
                type="text"
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                House nr prefix
              </span>
              <Input
                name="houseNrExt"
                value={formData.houseNrExt}
                onChange={handleInputChange}
                type="text"
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Street
              </span>
              <Input
                name="street"
                value={formData.street}
                onChange={handleInputChange}
                type="text"
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                City
              </span>
              <Input
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                type="text"
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                State
              </span>
              <Input
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                type="text"
                className="mt-1"
              />
            </label>

            <div>
              <Label className="text-sm">Country</Label>
              <Select
                className="mt-1.5"
                value={formData.country}
                name="country"
                onChange={handleInputChange} // Update to handle changes
                required
              >
                <option value="NL">Netherlands</option>
                <option value="BE">Belgium</option>
                <option value="GR">Germany</option>
              </Select>
            </div>

            {/* Password */}
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                type="password"
                className="mt-1"
                required
              />
            </label>

            <ButtonPrimary type="submit" disabled={loading}>
              {loading ? "Signing Up..." : "Continue"}
            </ButtonPrimary>
          </form>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account?{" "}
            <Link className="text-green-600" to="/login">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
