import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";

import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";

import TokenManager from "Apis/TokenManager";
import Prices from "components/Prices";
import BagIcon from "components/BagIcon";
import ProductStatus from "components/ProductStatus";
import ModalQuickView from "components/ModalQuickView";

export interface Product {
  categoryId: string;
  subCategoryId: string;
  id: string;
  name: string;
  price: number;
  quantity: number;
  discountId: string | null;
  description: string;
  dateAdded: string;
  variations: any[];
}

export interface ProductCardProps {
  className?: string;
  data: Product;
  isLiked?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({
  className = "",
  data,
  isLiked,
}) => {
  const {
    id,
    name,
    price,
    description,
    quantity,
    categoryId,
    subCategoryId,
    discountId,
    variations,
  } = data;
  const [variantActive, setVariantActive] = React.useState(0);
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);

  return (
    <>
      <div
        className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
        data-nc-id="ProductCard"
      >
        <Link to={"/admin/detail/" + id} className="absolute inset-0"></Link>

        <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
          <Link to={"/admin/detail/" + id} className="block">
            <NcImage
              containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
              src={variations ? variations[0].images[0] : ""}
              className="object-cover w-full h-full drop-shadow-xl"
            />
          </Link>

          {/* <ProductStatus status={"New in"} /> */}
          <div className="absolute top-3 right-3 px-2.5 py-1.5  dark:bg-slate-900 bg-white flex  justify-between">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className=""
              width={20}
              style={{ cursor: "pointer",margin:'0 3px' }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
              width={20}
              style={{ cursor: "pointer",margin:'0 3px' }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </div>

          {/* <LikeButton liked={isLiked} className="absolute top-3 right-3 z-10" /> */}

          {/* {sizes ? renderSizeList() : renderGroupButtons()} */}
          {/* {renderGroupButtons() } */}
        </div>

        <div className="space-y-4 px-2.5 pt-5 pb-2.5">
          {/* {renderVariants()} */}

          <div>
            <h2
              className={`nc-ProductCard__title text-base font-semibold transition-colors`}
            >
              {name}
            </h2>
            <div className="flex justify-between items-end ">
              <p className={`text-sm text-slate-500 dark:text-slate-400 mt-1 `}>
                {description}
              </p>
              <Prices price={price} />
            </div>
          </div>

          <div className="flex justify-between items-end ">
            {/* <div className="flex items-center mb-0.5">
              <StarIcon className="w-5 h-5 pb-[1px] text-amber-400" />
              <span className="text-sm ml-1 text-slate-500 dark:text-slate-400">
                {(Math.random() * 1 + 4).toFixed(1)} (
                {Math.floor(Math.random() * 70 + 20)} reviews)
              </span>
            </div> */}
          </div>
        </div>
      </div>

      {/* QUICKVIEW */}
      {/* <ModalQuickView
        show={showModalQuickView}
        product={data}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      /> */}
    </>
  );
};

export default ProductCard;
