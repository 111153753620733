import React, { FC, useEffect, useReducer, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import toast from "react-hot-toast";
import AuthApi from "Apis/AuthApi";

export interface PageLoginProps {
  className?: string;
}
const loginSocials = [
  // {
  //   name: "Continue with Facebook",
  //   href: "#",
  //   icon: facebookSvg,
  // },
  // {
  //   name: "Continue with Twitter",
  //   href: "#",
  //   icon: twitterSvg,
  // },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const emailReducer = (lasteState: any, dispatchedAction: any) => {
  if (dispatchedAction.type === "EMAIL_INPUT") {
    return {
      value: dispatchedAction.val,
      isValid: dispatchedAction.val.includes("@"),
    };
  }
  if (dispatchedAction.type === "INPUT_BLUR") {
    return { value: lasteState.val, isValid: lasteState.val?.includes("@") };
  }
  return { value: "", isValid: false };
};

const passwordReducer = (lasteState: any, dispatchedAction: any) => {
  if (dispatchedAction.type === "PASSWORD_INPUT") {
    return {
      value: dispatchedAction.val,
      isValid: dispatchedAction.val?.length > 3,
    };
  }
  return { value: "", isValid: false };
};

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [formIsValid, setFormIsValid] = useState();
  const [loading, setLoading] = useState(false);

  const [emailState, dispatchEmail] = useReducer(emailReducer, {
    value: "",
    isValid: false,
  });
  const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
    value: "",
    isValid: false,
  });

  const { isValid: emailIsValid } = emailState;
  const { isValid: passwordIsValid } = passwordState;

  useEffect(() => {
    const identifier = setTimeout(() => {
      setFormIsValid(emailIsValid && passwordIsValid);
    }, 200);
    return () => {
      clearTimeout(identifier);
    };
  }, [emailIsValid, passwordIsValid]);

  const emailChangeHandler = (event: any) => {
    dispatchEmail({ type: "EMAIL_INPUT", val: event.target.value });
  };
  const passwordChangeHandler = (event: any) => {
    dispatchPassword({ type: "PASSWORD_INPUT", val: event.target.value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (formIsValid) {
      setLoading(true);
      const { success, error, data }: any = await AuthApi.login({
        email: emailState.value,
        password: passwordState.value,
      });
      if (success) {
        setLoading(false);
        toast.success(`Welcome ${data.firstName}`); 
        navigate(from, { replace: true });
      } else {
        if (error?.status === 404) {
          toast.error("User could not be found");
          setLoading(false);
        } else {
          toast.error("Login failed");
          setLoading(false);
        }
      }
    } else {
      //dispatch(showSnackbarAsync({severity:"error",message:"Incorrect username or password. Please try again."}))
      toast.error(`Incorrect username or password. Please try again`);
    }
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login | Ood Blue</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                value={emailState.value}
                onChange={emailChangeHandler}
                placeholder="example@example.com"
                className="mt-1"
              />
              {!emailState.isValid && emailState.value && (
                <p className="text-red-500 text-sm">
                  Please enter a valid email
                </p>
              )}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm text-green-600">
                  Forgot password?
                </Link>
              </span>
              <Input
                type="password"
                value={passwordState.value}
                onChange={passwordChangeHandler}
                className="mt-1"
              />
              {!passwordState.isValid && passwordState.value && (
                <p className="text-red-500 text-sm">
                  Password must be at least 4 characters long
                </p>
              )}
            </label>
            <ButtonPrimary type="submit" disabled={loading || !formIsValid}>
              {loading ? "Logging in..." : "Continue"}
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link className="text-green-600" to="/signup">
              Create an account
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
