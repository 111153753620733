import React, { useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import ProductsApi from "Apis/ProductsApi";
import toast from "react-hot-toast";
import ModalDeleteProduct from "components/ModalDeleteProduct";
import { useNavigate } from "react-router-dom";

const colors = ["red", "sky", "slate"];

const VariationCard = ({ variation,product }: any) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [item, setItem] = useState<any>();
  const [show, setShow] = useState(false);
  const [updateToggled, setUpdateToggled] = useState(true);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [attributes, setAttributes] = useState<any>();
  const [images, setImages] = useState("");

  const handleSetAttributes = async (value: any) => {
    setAttributes([
      {
        name: "color",
        value: value,
      },
    ]);
  };

  useEffect(() => {
    setName(variation?.name);
    setPrice(variation?.price);
    setQuantity(variation?.quantity);
    setAttributes(variation?.attributes);
    setImages(variation?.images);
  }, [variation]);

  const handleUpdateProduct = async () => {
    if (updateToggled) {
      setUpdateToggled(false);
      return;
    }
    const payload = {
      id: variation?.id,
      productId: variation?.id,
      name: name,
      price: price,
      quantity: quantity,
      Attributes: attributes,
      Images: images,
    };
    const response = await ProductsApi.updateVariation(payload);
    if (response.success) {
      setUpdateToggled(true);
      toast.success("Product Updated Successfuly");
    } else {
      toast.error("Can not update product");
    }
  };

  const handleDeleteProduct = async () => {
    if (!updateToggled) {
      setUpdateToggled(true);
      return;
    }
    setShow(true);
    const payload = {
      productId: product?.id,
      id: variation?.id,
    };
    const response = await ProductsApi.removeVariation(payload);
    if (response.success) {
      navigate("/admin/products");
      toast.success("variation deleted successfully");
    } else {
      toast.error("Could not delete variation");
    }
  };

  return (
    <div className="border p-5 relative">
      {/* comment */}
      <div className="mt-10">
        <div className="absolute top-10 right-10 space-x-3">
          <ButtonPrimary
            className="flex-1 flex-shrink-0 bg-red-900"
            onClick={handleDeleteProduct}
          >
            {updateToggled ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                  width={20}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </>
            ) : (
              "Cancel"
            )}
          </ButtonPrimary>
          <ButtonPrimary
            className="flex-1 flex-shrink-0 bg-green-900"
            onClick={handleUpdateProduct}
          >
            {updateToggled ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                  width={20}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
              </>
            ) : (
              "Save"
            )}
          </ButtonPrimary>
        </div>
        <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
          <div>
            <Label>Name</Label>
            <Input
              disabled={updateToggled}
              type="text"
              className="mt-1.5"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <Label>Price</Label>
            <Input
              disabled={updateToggled}
              type="text"
              className="mt-1.5"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          <div>
            <Label>Quantity</Label>
            <Input
              disabled={updateToggled}
              type="text"
              className="mt-1.5"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-2">
          <Label className="text-sm">Color</Label>
          <Select
            disabled={updateToggled}
            className="mt-1.5"
            value={attributes ? attributes[0]?.value : ""}
            onChange={(e) => handleSetAttributes(e.target.value)}
          >
            {colors?.map((color: any) => {
              return <option value={color}>{color}</option>;
            })}
          </Select>
        </div>
        <header className=" mt-8 sm:mt-10">
          <div className="relative flex items-center">
            <div
              className="col-span-2 md:col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => setIsOpen(true)}
              style={{ height: "200px", width: "200px" }}
            >
              {/* <ButtonPrimary>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                  width={20}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </ButtonPrimary> */}
              <NcImage
                containerClassName="aspect-w-3 aspect-h-4 md:absolute md:inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={variation && variation?.images[0]}
              />
            </div>

            {/* <div
          className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-white text-slate-500 cursor-pointer hover:bg-slate-200 z-10"
          onClick={() => setIsOpen(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
            />
          </svg>
          <span className="ml-2 text-neutral-800 text-sm font-medium">
            Show all photos
          </span>
        </div> */}
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={variation?.images}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          uniqueClassName="nc-ProductDetailPage2__modalPhotos"
        />
      </div>
      <ModalDeleteProduct
        productName={name}
        show={show}
        onCloseModalReportItem={() => setShow(false)}
        handleDeleteProduct={handleDeleteProduct}
      />
    </div>
  );
};

export default VariationCard;
