import React, { FC, useEffect, useState } from "react";

import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import Radio from "shared/Radio/Radio";
import MySwitch from "components/MySwitch";
import { Product } from "data/data";
import CategoriesApi from "Apis/CategoriesApi";
import SubCategoryApi from "Apis/SubCategories";

// DEMO DATA
const DATA_categories = [
  {
    name: "Action Figures & Playsets",
  },
  {
    name: "Dolls & Plush Toys",
  },
  {
    name: "Educational Toys",
  },
  {
    name: "Building Blocks & Construction",
  },
  {
    name: "Outdoor & Sports Toys",
  },
  {
    name: "Arts & Crafts Kits",
  },
  {
    name: "Vehicles & Remote Control Toys",
  },
  {
    name: "Pretend Play & Dress-Up",
  },
];

const DATA_colors = [
  { name: "White" },
  { name: "Beige" },
  { name: "Blue" },
  { name: "Black" },
  { name: "Brown" },
  { name: "Green" },
  { name: "Navy" },
];

const DATA_sizes = [
  { name: "XS" },
  { name: "S" },
  { name: "M" },
  { name: "L" },
  { name: "XL" },
  { name: "2XL" },
];

const DATA_ages = [
  { name: "Infants (0-12 Months)" },
  { name: "Toddlers (1-3 Years)" },
  { name: "Preschool (3-5 Years)" },
  { name: "Kids (5-7 Years)" },
  { name: "Older Kids (8-12 Years)" },
];

const DATA_sortOrderRadios = [
  { name: "Most Popular", id: "Most-Popular" },
  { name: "Best Rating", id: "Best-Rating" },
  { name: "Newest", id: "Newest" },
  { name: "Price Low - High", id: "Price-low-high" }, // Fixed typo
  { name: "Price High - Low", id: "Price-high-low" }, // Fixed typo
];

const PRICE_RANGE = [5, 500];
//

export interface FiltersProps {
  products?: any[];
  setFilteredProducts: any;
}

const SidebarFilters: FC<FiltersProps> = ({
  products,
  setFilteredProducts,
}) => {
  const [loading, setLoading] = useState();
  const [categories, setCategories] = useState<any>([]);
  const [subCategories, setSubCategories] = useState<any>([]);
  const [isOnSale, setIsIsOnSale] = useState(false);
  const [rangePrices, setRangePrices] = useState([5, 500]);
  const [categoriesState, setCategoriesState] = useState<string[]>([]);
  const [colorsState, setColorsState] = useState<string[]>([]);
  const [agesState, setAgesState] = useState<string[]>([]);
  const [sortOrderStates, setSortOrderStates] = useState<string>("");

  const availableColors = products?.reduce((acc, product) => {
    product.variations?.forEach((variation: { attributes: any[] }) => {
      // Find the color attribute in the attributes array
      const colorAttr = variation.attributes?.find(
        (attr: { name: string }) => attr.name === "color"
      )?.value;

      if (colorAttr && !acc.includes(colorAttr)) {
        acc.push(colorAttr);
      }
    });

    return acc;
  }, []);
  // Filtering function
  const filterProducts = () => {
    let filtered = products || [];

    // Filter by categories
    if (categoriesState.length > 0) {
      filtered = filtered.filter((product) =>
        categoriesState.includes(product.categoryId)
      );
    }

    // Filter by sub categories
    if (agesState.length > 0) {
      filtered = filtered.filter((product) =>
        agesState.includes(product.subCategoryId)
      );
    }

    // Filter by colors
    if (colorsState.length > 0) {
      filtered = filtered.filter((product) =>
        product.variations?.some((variation: { attributes: any[] }) =>
          variation.attributes?.some(
            (attr: { name: string; value: string }) =>
              attr.name === "color" && colorsState.includes(attr.value)
          )
        )
      );
    }

    // Filter by price range
    filtered = filtered.filter(
      (product) =>
        product.priceExcludingTax / 100 >= rangePrices[0] &&
        product.priceExcludingTax / 100 <= rangePrices[1]
    );

    // Filter by on sale
    // if (isOnSale) {
    //   filtered = filtered.filter((product) => product.status);
    // }

    // Sort products
    switch (sortOrderStates) {
      case "Most-Popular":
        // filtered = filtered.sort((a, b) => b.popularity - a.popularity);
        break;
      case "Best-Rating":
        console.log("Best entered");
        // filtered = filtered.sort((a, b) => b.rating - a.rating);
        break;
      case "Newest":
        filtered = filtered.sort(
          (a, b) =>
            new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime()
        );
        break;
      case "Price-low-high":
        filtered = filtered.sort(
          (a, b) => a.priceExcludingTax - b.priceExcludingTax
        );
        break;
      case "Price-high-low":
        filtered = filtered.sort(
          (a, b) => b.priceExcludingTax - a.priceExcludingTax
        );
        break;
      default:
        break;
    }

    // Update the filtered products
    console.log("high filtered", filtered);
    setFilteredProducts(filtered);
  };

  // Call filterProducts whenever any filter state changes
  useEffect(() => {
    filterProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangePrices, categoriesState, colorsState, sortOrderStates, agesState]);

  const getCategories = async () => {
    const response = await CategoriesApi.getAllCategories();
    // console.log("categories",response)
    if (response.success) {
      setCategories(response.data);
    } else {
      setCategories([]);
    }
  };

  const getSubCategories = async () => {
    const response = await SubCategoryApi.getAllSubCategories();
    //  console.log("subcategories",response)
    if (response.success) {
      setSubCategories(response.data);
    } else {
      setSubCategories([]);
    }
  };

  useEffect(() => {
    getCategories();
    getSubCategories();
  }, [agesState]);

  const handleChangeCategories = (checked: boolean, id: string) => {
    checked
      ? setCategoriesState([...categoriesState, id])
      : setCategoriesState(categoriesState.filter((i) => i !== id));
  };

  const handleChangeColors = (checked: boolean, name: string) => {
    checked
      ? setColorsState([...colorsState, name])
      : setColorsState(colorsState.filter((i) => i !== name));
  };

  const handleChangeAges = (checked: boolean, name: string) => {
    checked
      ? setAgesState([...agesState, name])
      : setAgesState(agesState.filter((i) => i !== name));
  };

  // OK
  const renderTabsCategories = () => {
    return (
      <div className="relative flex flex-col pb-8 space-y-4">
        <h3 className="font-semibold mb-2.5">Categories</h3>
        {categories.map((item: any) => (
          <div key={item.id} className="">
            <Checkbox
              name={item.name}
              label={item.name}
              defaultChecked={categoriesState.includes(item.id)}
              sizeClassName="w-5 h-5"
              labelClassName="text-sm font-normal"
              onChange={(checked) => handleChangeCategories(checked, item.id)}
            />
          </div>
        ))}
      </div>
    );
  };

  // OK
  const renderTabsAges = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-4">
        <h3 className="font-semibold mb-2.5">Sub Categories</h3>
        {subCategories.map((item: any) => (
          <div key={item.id} className="">
            <Checkbox
              name={item.name}
              label={item.name}
              defaultChecked={agesState.includes(item.name)}
              onChange={(checked) => handleChangeAges(checked, item.id)}
              sizeClassName="w-5 h-5"
              labelClassName="text-sm font-normal"
            />
          </div>
        ))}
      </div>
    );
  };

  // OK
  const renderTabsColor = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-4">
        <h3 className="font-semibold mb-2.5">Colors</h3>
        {availableColors?.map((item: any, indx: any) => (
          <div key={indx} className="flex items-center space-x-2">
            <Checkbox
              sizeClassName="w-5 h-5"
              labelClassName="text-sm font-normal"
              name={item}
              defaultChecked={colorsState.includes(item)}
              onChange={(checked) => handleChangeColors(checked, item)}
            />
            <div
              className="w-5 h-5 rounded-full border border-neutral-300"
              style={{ backgroundColor: item }}
              title={item}
            ></div>
            {/* <span className="text-sm">{item}</span> */}
          </div>
        ))}
      </div>
    );
  };

  // OK
  const renderTabsPriceRage = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-5 pr-3">
        <div className="space-y-5">
          <span className="font-semibold">Price range</span>
          <Slider
            range
            min={PRICE_RANGE[0]}
            max={PRICE_RANGE[1]}
            step={1}
            defaultValue={[rangePrices[0], rangePrices[1]]}
            allowCross={false}
            onChange={(_input: number | number[]) =>
              setRangePrices(_input as number[])
            }
          />
        </div>

        <div className="flex justify-between space-x-5">
          <div>
            <label
              htmlFor="minPrice"
              className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              Min price
            </label>
            <div className="mt-1 relative rounded-md">
              <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-sm">
                €
              </span>
              <input
                type="text"
                name="minPrice"
                disabled
                id="minPrice"
                className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                value={rangePrices[0]}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="maxPrice"
              className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              Max price
            </label>
            <div className="mt-1 relative rounded-md">
              <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-sm">
                €
              </span>
              <input
                type="text"
                disabled
                name="maxPrice"
                id="maxPrice"
                className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent"
                value={rangePrices[1]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // OK
  const renderTabsSortOrder = () => {
    return (
      <div className="relative flex flex-col py-8 space-y-4">
        <h3 className="font-semibold mb-2.5">Sort order</h3>
        {DATA_sortOrderRadios.map((item) => (
          <Radio
            id={item.id}
            key={item.id}
            name="radioNameSort"
            label={item.name}
            defaultChecked={sortOrderStates === item.id}
            sizeClassName="w-5 h-5"
            onChange={setSortOrderStates}
            className="!text-sm"
          />
        ))}
      </div>
    );
  };

  return (
    <div className="divide-y divide-slate-200 dark:divide-slate-700">
      {renderTabsCategories()}
      {renderTabsAges()}
      {renderTabsColor()}
      {renderTabsPriceRage()}
      <div className="py-8 pr-2">
        <MySwitch
          label="On sale!"
          desc="Products currently on sale"
          enabled={isOnSale}
          onChange={setIsIsOnSale}
        />
      </div>
      {renderTabsSortOrder()}
    </div>
  );
};

export default SidebarFilters;
