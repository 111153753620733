import axios from "axios";
import TokenManager from "./TokenManager";
import ApiKeyEnc from "./ApiKeyEnc";

const SWAGGER_CHECKOUT_URL =process.env.REACT_APP_SWAGGER_BASE_URL +"/Checkout";
const SWAGGER_ORDERS_URL =process.env.REACT_APP_SWAGGER_BASE_URL +"/Orders";
  const API_KEY= process.env.REACT_APP_API_KEY;
  
  const OrdersApi = {
    getAllOrders: async (token) => {
      try {
        const encKey=await  ApiKeyEnc.encrypt(API_KEY)
        const response = await axios.get(token ? SWAGGER_ORDERS_URL+"?ContinuationToken="+token:SWAGGER_ORDERS_URL, {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
            "x-api-key": encKey,
          },
        });
        return { success: true, data: response.data.data };
      } catch (error) {
        return { success: false, error: error };
      }
    },
    getOrderById: async (id) => {
      try {
        const encKey=await  ApiKeyEnc.encrypt(API_KEY)
        const response = await axios.get(SWAGGER_ORDERS_URL+"/"+id, {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
            "x-api-key": encKey,
          },
        });
        return { success: true, data: response.data.data };
      } catch (error) {
        return { success: false, error: error };
      }
    },
    createOrder: async (payload) => {
      try {
        const encKey=await  ApiKeyEnc.encrypt(API_KEY)
        const response = await axios.post(SWAGGER_CHECKOUT_URL,payload, {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
            "x-api-key": encKey,
          },
        });
        return { success: true, data: response.data.data };
      } catch (error) {
        return { success: false, error: error };
      }
    },
    updateOrder: async (payload) => {
      try {
        const encKey=await  ApiKeyEnc.encrypt(API_KEY)
        const response = await axios.patch(SWAGGER_ORDERS_URL,payload, {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
            "x-api-key": encKey,
          },
        });
        return { success: true, data: response.data.data };
      } catch (error) {
        return { success: false, error: error };
      }
    },
    deleteOrder: async (id) => {
      try {
        const encKey=await  ApiKeyEnc.encrypt(API_KEY)
        const response = await axios.delete(SWAGGER_ORDERS_URL+"/"+id, {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
            "x-api-key": encKey,
          },
        });
        return { success: true, data: response.data.data };
      } catch (error) {
        return { success: false, error: error };
      }
    },
  };
export default OrdersApi;
