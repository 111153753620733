import React, { FC, useState } from "react";
import { Helmet } from "react-helmet-async";
import emailjs from "emailjs-com";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionPromo2 from "components/SectionPromo2";
import toast from "react-hot-toast";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: `Montageweg 35, 1948 PH Beverwijk.\nHal5, Unit 05265`,
  },
  {
    title: "💌 EMAIL",
    desc: "info@oodblue.nl",
  },
  {
    title: "☎ PHONE",
    desc: "+31644663161",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSendEmail = (e: React.FormEvent) => {
    e.preventDefault();

    const templateParams = {
      from_name: fullName,
      from_email: email,
      message: message,
    };

    emailjs
      .send(
        "service_si6zy5r", // Replace with your Service ID
        "template_3o4shns", // Replace with your Template ID
        templateParams,
        "zrF0Yg5WEtASSRNK6" // Replace with your User ID from EmailJS
      )
      .then(
        (response) => {
          // console.log("SUCCESS!", response.status, response.text);
          toast.success("Message sent successfully!");
          setFullName("")
          setEmail("")
          setMessage("")
        },
        (err) => {
          console.error("FAILED...", err);
          toast.error("Failed to send message, please try again later.");
        }
      );
  };

  return (
    <div className={`nc-PageContact overflow-hidden ${className}`} data-nc-id="PageContact">
      <Helmet>
        <title>Contact | OOd Blue</title>
      </Helmet>
      <div className="">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc.split("\n").map((line, index) => (
                      <div key={index}>{line}</div>
                    ))}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSendEmail}>
                <label className="block">
                  <Label>Full name</Label>
                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>
                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>
                  <Textarea
                    className="mt-1"
                    rows={6}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </label>
                <div>
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="relative my-24 lg:my-32 py-24 lg:py-32">
          <SectionPromo2 />
        </div>
      </div>
    </div>
  );
};

export default PageContact;
