import axios from "axios";
import TokenManager from "./TokenManager";
import ApiKeyEnc from "./ApiKeyEnc";

const SWAGGER_CARTS_URL =process.env.REACT_APP_SWAGGER_BASE_URL +"/Carts";
const API_KEY = process.env.REACT_APP_API_KEY;

const CartsApi = {
  getCart: async () => {
    try {
      const encKey=await  ApiKeyEnc.encrypt(API_KEY)
      const response = await axios.get(SWAGGER_CARTS_URL, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  addItem: async (payload) => {
    try {
      const encKey=await  ApiKeyEnc.encrypt(API_KEY)
      const response = await axios.post(
        SWAGGER_CARTS_URL + "/additem",
        payload,
        {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
            "x-api-key": encKey,
          },
        }
      );
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  removeItem: async (payload) => {
    try {
      const encKey=await  ApiKeyEnc.encrypt(API_KEY)
      const response = await axios.post(
        SWAGGER_CARTS_URL + "/removeitem",
        payload,
        {
          headers: {
            Authorization: `Bearer ${TokenManager.getAccessToken()}`,
            "x-api-key": encKey,
          },
        }
      );
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  clearCartById: async (id) => {
    try {
      const encKey=await  ApiKeyEnc.encrypt(API_KEY)
      const response = await axios.delete(SWAGGER_CARTS_URL + "/" + id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
};
export default CartsApi;
