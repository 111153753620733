import axios from "axios";
import TokenManager from "./TokenManager";
import ApiKeyEnc from "./ApiKeyEnc";

const SWAGGER_USERS_URL = process.env.REACT_APP_SWAGGER_BASE_URL + "/Users";
const API_KEY = process.env.REACT_APP_API_KEY;

const AuthApi = {
  login: async (payload) => {
    try {
      const encKey = await ApiKeyEnc.encrypt(API_KEY);
      const response = await axios.post(SWAGGER_USERS_URL + "/login", payload, {
        headers: {
          "x-api-key": encKey,
        },
      });
      const token = response.data.data.access_Token;
      TokenManager.setAccessToken(token);
      const user = {
        id: response.data.data?.id,
        firstName: response.data.data?.firstName,
        middleName: response.data.data?.middleName,
        lastName: response.data.data?.lastName,
        email: response.data.data?.email,
        phoneNumber: response.data.data?.phoneNumber,
        shippingAddress: response.data.data?.shippingAddress,
      };
      TokenManager.setUserDetails(user);
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  register: async (payload) => {
    try {
      const encKey = await ApiKeyEnc.encrypt(API_KEY);
      const response = await axios.post(
        SWAGGER_USERS_URL + "/register",
        payload,
        {
          headers: {
            "x-api-key": encKey,
          },
        }
      );
      const token = response.data.data.access_Token;
      TokenManager.setAccessToken(token);
      const claims = TokenManager.getClaims();
      const user = {
        id: response.data.data?.id,
        firstName: response.data.data?.firstName,
        middleName: response.data.data?.middleName,
        lastName: response.data.data?.lastName,
        email: response.data.data?.email,
        phoneNumber: response.data.data?.phoneNumber,
        shippingAddress: response.data.data?.shippingAddress,
      };
      TokenManager.setUserDetails(user);
      return { success: true, data: { token, claims } };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  getUser: async (id) => {
    try {
      const encKey = await ApiKeyEnc.encrypt(API_KEY);
      const response = await axios.get(SWAGGER_USERS_URL + "/" + id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  updateUser: async (payload) => {
    try {
      const encKey = await ApiKeyEnc.encrypt(API_KEY);
      const response = await axios.put(SWAGGER_USERS_URL, payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
};
export default AuthApi;
