import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import toast from "react-hot-toast";
import PasswordApi from "Apis/PasswordApi";
import Label from "components/Label/Label";

export interface PageLoginProps {
  className?: string;
}
const PageResetPass: FC<PageLoginProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState<any>("");
  const [confirmPassword, setConfirmPassword] = useState<any>("");

  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

  useEffect(() => {
    if (id) {
      if (!uuidRegex.test(id)) {
        navigate("/page404");
      }
    }
  }, []);

  const resetPassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("Password does not match");
      return;
    }
    if (newPassword === "" || confirmPassword === "") {
      toast.error("please fill in all inputs");
      return;
    }
    setLoading(true);
    const payload = {
      newPassword: newPassword,
      token: id,
    };
    const response = await PasswordApi.resetPassword(payload); 
    if (response.success) {
      toast.success("rest link was sent to your email");
      setNewPassword("");
      setConfirmPassword("");
      navigate('/')
    } else {
      toast.error("could not reset password");
    }
    setLoading(false);
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Forget Password | Ood Blue</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Reset Password
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
          </div>
          {/* FORM */}

            <div> 
              <Label>New password</Label>
              <Input
                type="password"
                className="mt-1.5"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div>
              <Label>Confirm password</Label>
              <Input
                type="password"
                className="mt-1.5"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <ButtonPrimary disabled={loading} onClick={resetPassword}>
              Rest
            </ButtonPrimary>

        </div>
      </div>
    </div>
  );
};

export default PageResetPass;
