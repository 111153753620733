import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import AccordionInfo from "./AccordionInfo";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { TOYS_PRODUCTS } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import Policy from "./Policy";
import ReviewItem from "components/ReviewItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SectionPromo2 from "components/SectionPromo2";
import ModalViewAllReviews from "./ModalViewAllReviews";
import NotifyAddTocart from "components/NotifyAddTocart";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import ProductsApi from "Apis/ProductsApi";
import CategoriesApi from "Apis/CategoriesApi";
import SubCategoryApi from "Apis/SubCategories";
import { useNavigate, useParams } from "react-router-dom";
import ModalReportItem from "components/ModalReportItem";
import ModalDeleteProduct from "components/ModalDeleteProduct";
import ModalPhotos from "./ModalPhotos";
import NcImage from "shared/NcImage/NcImage";
import ButtonCircle from "shared/Button/ButtonCircle";
import VariationCard from "./VariationCard";

export interface ProductDetailPageProps {
  className?: string;
}

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [updateToggled, setUpdateToggled] = useState(true);
  const [name, setName] = React.useState("");
  const [product, setProduct] = useState<any>(false);
  const [description, setDescription] = React.useState("");
  const [categories, setCategories] = useState<any>([]);
  const [category, setCategory] = React.useState<any>();
  const [subCategory, setSubCategory] = React.useState<any>();
  const [subCategories, setSubCategories] = useState<any>([]);
  const [discount, setDiscount] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [quantity, setQuantity] = React.useState("");

  const { id } = useParams();

  const getCategories = async () => {
    const response = await CategoriesApi.getAllCategories();
    // console.log("categories",response)
    if (response.success) {
      setCategories(response.data);
    } else {
      setCategories([]);
    }
  };

  const getSubCategories = async () => {
    const response = await SubCategoryApi.getAllSubCategories();
    //  console.log("subcategories",response)
    if (response.success) {
      setSubCategories(response.data);
    } else {
      setSubCategories([]);
    }
  };

  const getProductById = async (id: string) => {
    setLoading(true);
    const response = await ProductsApi.getProductById(id);
    if (response.success) {
      setProduct(response.data);
    } else {
      toast.error("Could not get product");
    }
  };

  const getVariationFromProduct = (variationId: any) => {
    return product?.variations?.find(
      (variation: any) => variation.id === variationId
    );
  };

  const handleUpdateProduct = async () => {
    if (updateToggled) {
      setUpdateToggled(false);
      return;
    }
    const payload = {
      id: product?.id,
      name: name,
      describtion: description,
      categoryId: category,
      subCategoryId: subCategory,
      price: price,
      quantity: quantity,
    };
    const response = await ProductsApi.updateProduct(payload);
    if (response.success) {
      setUpdateToggled(true);
      toast.success("Product Updated Successfuly");
    } else {
      toast.error("Can not update product");
    }
  };

  const handleDeleteProduct = async () => {
    if (!updateToggled) {
      setUpdateToggled(true);
      return;
    }
    setShow(true);
    // const response = await ProductsApi.deleteProduct(id)
    // if(response.success){
    //   navigate('/admin/products')
    //   toast.success("product deleted successfully")
    // }else{
    //   toast.error("Could not delete product")
    // }
  };

  useEffect(() => {
    getCategories();
    getSubCategories();
  }, []);

  useEffect(() => {
    if (product) {
      setCategory(product.categoryId);
      setSubCategory(product.subCategoryId);
      setPrice(product.price);
      setQuantity(product.quantity);
      setDescription(product.description);
      setName(product.name);
    } else {
      setCategory(categories[0]?.id);
      setSubCategory(subCategories[0]?.id);
    }
  }, [product]);

  useEffect(() => {
    if (id) {
      getProductById(id);
    }
  }, [id]);

  console.log(product);

  const renderSectionContent = () => {
    return (
      <div className="space-y-7 2xl:space-y-8">
        {/* ---------- 1 HEADING ----------  */}
        <h2 className="text-2xl font-semibold flex ">Product</h2>
        <div>
          <div>
            <Label>Name</Label>
            <Input
              disabled={updateToggled}
              type="text"
              className="mt-1.5"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            <div>
              <Label className="text-sm">Category</Label>
              <Select
                disabled={updateToggled}
                className="mt-1.5"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {categories?.map((cat: any) => {
                  return <option value={cat?.id}>{cat?.name}</option>;
                })}
              </Select>
            </div>
            <div>
              <Label className="text-sm">Sub Category</Label>
              <Select
                disabled={updateToggled}
                className="mt-1.5"
                value={subCategory}
                onChange={(e) => setSubCategory(e.target.value)}
              >
                {subCategories?.map((cat: any) => {
                  return <option value={cat?.id}>{cat?.name}</option>;
                })}
              </Select>
            </div>
            {/* <div>
              <Label className="text-sm">Discount</Label>
              <Select
                className="mt-1.5"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
              >
                <option value="Netherlands">Netherlands</option>
                <option value="Belguim">Belguim</option>
                <option value="Germany">Germany</option>
              </Select>
            </div> */}
          </div>

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            <div>
              <Label>Price</Label>
              <Input
                disabled={updateToggled}
                type="text"
                className="mt-1.5"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
            <div>
              <Label>Quantity</Label>
              <Input
                disabled={updateToggled}
                type="text"
                className="mt-1.5"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
          </div>
          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            <div>
              <Label>Description</Label>
              <Textarea
                disabled={updateToggled}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{ width: "500px" }}
              />
            </div>
          </div>

          {/* <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            <Prices
              contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
              price={112}
            />

            <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div>

            <div className="flex items-center">
              <a
                href="#reviews"
                className="flex items-center text-sm font-medium"
              >
                <StarIcon className="w-5 h-5 pb-[1px] text-yellow-400" />
                <div className="ml-1.5 flex">
                  <span>4.9</span>
                  <span className="block mx-2">·</span>
                  <span className="text-slate-600 dark:text-slate-400 underline">
                    142 reviews
                  </span>
                </div>
              </a>
              <span className="hidden sm:block mx-2.5">·</span>
              <div className="hidden sm:flex items-center text-sm">
                <SparklesIcon className="w-3.5 h-3.5" />
                <span className="ml-1 leading-none">{status}</span>
              </div>
            </div>
          </div> */}
        </div>

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5">
          <ButtonPrimary
            className="flex-1 flex-shrink-0 bg-red-900"
            onClick={handleDeleteProduct}
          >
            {updateToggled ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                  width={20}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>

                <span className="ml-3">Delete Product</span>
              </>
            ) : (
              "Cancel"
            )}
          </ButtonPrimary>
          <ButtonPrimary
            className="flex-1 flex-shrink-0 bg-green-900"
            onClick={handleUpdateProduct}
          >
            {updateToggled ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                  width={20}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
                <span className="ml-3">Update Product</span>
              </>
            ) : (
              "Save"
            )}
          </ButtonPrimary>
        </div>

        {/*  */}
        {/* <hr className=" 2xl:!my-10 border-slate-200 dark:border-slate-700"></hr> */}
        {/*  */}

        {/* ---------- 5 ----------  */}
        {/* <AccordionInfo /> */}
      </div>
    );
  };

  return (
    <div className={`nc-ProductDetailPage ${className}`}>
      {/* MAIn */}
      <main className="container mt-5 lg:mt-11">
        <div className="lg:flex">
          {/* CONTENT */}
          {/* <div className="w-full lg:w-[55%] ">

            <div className="relative">
              <div className="aspect-w-16 aspect-h-16">
                <img
                  src={LIST_IMAGES_DEMO[0]}
                  className="w-full rounded-2xl object-cover"
                  alt="product detail 1"
                />
              </div>
              {renderStatus()}

            </div>
            <div className="grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-8 xl:mt-8">
              {[LIST_IMAGES_DEMO[1], LIST_IMAGES_DEMO[2]].map((item, index) => {
                return (
                  <div
                    key={index}
                    className="aspect-w-11 xl:aspect-w-10 2xl:aspect-w-11 aspect-h-16"
                  >
                    <img
                      src={item}
                      className="w-full rounded-2xl object-cover"
                      alt="product detail 1"
                    />
                  </div>
                );
              })}
            </div>
          </div> */}

          {/* SIDEBAR */}
          <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
            {renderSectionContent()}
          </div>
        </div>

        {/* DETAIL AND REVIEW */}
        <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">
          <hr className="border-slate-200 dark:border-slate-700" />
          {/* HEADING */}
          <h2 className="text-2xl font-semibold flex items-center">
            {/* <StarIcon className="w-7 h-7 mb-0.5" /> */}
            <span className="ml-1.5">Variations {product?.variations?.length}</span>
          </h2>

          {product?.variations?.map((variation: any) => {
            return <VariationCard variation={variation} product={product}/>;
          })}

          <hr className="border-slate-200 dark:border-slate-700" />
        </div>
      </main>
      <ModalDeleteProduct
        productName={name+" product"}
        show={show}
        onCloseModalReportItem={() => setShow(false)}
        handleDeleteProduct={handleDeleteProduct}
      />
    </div>
  );
};

export default ProductDetailPage;
