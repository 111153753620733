import Label from "components/Label/Label";
import React, { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import toast from "react-hot-toast";
import PasswordApi from "Apis/PasswordApi";

const AccountPass = () => {
  const [loading,setLoading]=useState(false)
  const [currentPassword,setCurrentPassword]=useState<any>('') 
  const [newPassword,setNewPassword]=useState<any>('')
  const [confirmPassword,setConfirmPassword]=useState<any>('')


  const updatePassword=async()=>{

      if(newPassword !== confirmPassword){
        toast.error("Password does not match")
        return
      }
      if(currentPassword ==='' || newPassword==='' || confirmPassword===''){
        toast.error("please fill in all inputs")
        return
      }
      setLoading(true)
      const payload = {
        oldPassword:currentPassword,
        newPassword:confirmPassword
      }
      const response = await PasswordApi.changePassword(payload)
      if(response.success){
        toast.success("password changed successfully")
        setCurrentPassword('')
        setNewPassword("")
        setConfirmPassword("")
      }else{
        toast.error("could not change password")
      }
      setLoading(false)
  }
  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            Update your password
          </h2>
          <div className=" max-w-xl space-y-6">
            <div>
              <Label>Current password</Label>
              <Input type="password" className="mt-1.5" value={currentPassword} onChange={(e)=>setCurrentPassword(e.target.value)} required/>
            </div>
            <div>
              <Label>New password</Label>
              <Input type="password" className="mt-1.5" value={newPassword} onChange={(e)=>setNewPassword(e.target.value)} required/>
            </div>
            <div>
              <Label>Confirm password</Label> 
              <Input type="password" className="mt-1.5" value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)} required/>
            </div>
            <div className="pt-2">
              <ButtonPrimary onClick={updatePassword} disabled={loading}>Update password</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
