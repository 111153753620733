import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import { avatarImgs } from "contains/fakeData";
import TokenManager from "Apis/TokenManager";
import toast from "react-hot-toast";
import AuthApi from "Apis/AuthApi";

export interface AccountPageProps {
  className?: string;
}

const  AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const[loading,setLoading]=useState(false)
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [postCode, setPostCode] = useState<string>("");
  const [houseNrExt, setHouseNrExt] = useState<string>("");
  const [houseNr, setHouseNr] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [phoneNr, setPhoneNr] = useState<string>("");

  // Fetch user details on component mount
  useEffect(() => {
    const user = TokenManager.getUserDetails();
    if (user) {
      setFirstName(user.firstName || "");
      setLastName(user.lastName || "");
      setEmail(user.email || "");
      setCity(user?.shippingAddress?.city || "");
      setStreet(user?.shippingAddress?.street || "");
      setState(user?.shippingAddress?.state || "");
      setPostCode(user?.shippingAddress?.postCode || "");
      setCountry(user?.shippingAddress?.country || "Netherlands");
      setPhoneNr(user.phoneNumber || "");
      setHouseNrExt(user?.shippingAddress?.houseNrExt ||  "");
      setHouseNr(user?.shippingAddress?.houseNr ||  "");
    }
  }, [currentUser]);

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate all fields are filled
    if (!firstName || !lastName || !email || !city || !state || !postCode || !country || !phoneNr || !houseNr) {
      toast.error("All fields are required!"); 
      return;
    } 

    const updatedUser = {
      firstName,
      lastName,
      email,
      shippingAddress: {
        city,
        street,
        postCode,
        houseNr,
        country,
        state,
        houseNrExt
      },
      phoneNumber: phoneNr,
    };

    try {
      setLoading(true)
      const response = await AuthApi.updateUser(updatedUser)
      if(response.success){
        const user = response.data
        TokenManager.setUserDetails(user);
        setCurrentUser(user)
        TokenManager.setUserDetails(user)
        toast.success("Account updated successfully!"); 
      }else{
        toast.error("Failed to update account!"); 
      }
      setLoading(false)
    } catch (error) {
      console.error(error);
      toast.error("Failed to update account!"); 
      setLoading(false)
    }
  };
   
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account | Ood Blue</title>
      </Helmet>
      <CommonLayout currentUser={currentUser}>
        <form onSubmit={handleUpdate} className="space-y-10 sm:space-y-12">
          <h2 className="text-2xl sm:text-3xl font-semibold">
            Account Information
          </h2>
          <div className="flex flex-col md:flex-row">
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                <div>
                  <Label className="text-sm">First name</Label>
                  <Input
                    className="mt-1.5"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <Label className="text-sm">Last name</Label>
                  <Input
                    className="mt-1.5"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div>
                <Label>Email</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 bg-neutral-50 text-neutral-500 text-sm">
                    <i className="text-2xl las la-envelope"></i>
                  </span>
                  <Input
                    className="!rounded-l-none"
                    placeholder="example@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
              <div>
                <Label className="text-sm">Street</Label>
                <Input
                  className="mt-1.5"
                  placeholder="Street 1"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  required
                />
              </div>
              <div>
                <Label className="text-sm">House nr</Label>
                <Input
                  className="mt-1.5"
                  placeholder="35"
                  value={houseNr}
                  onChange={(e) => setHouseNr(e.target.value)}
                  required
                />
              </div>

              </div>
              
              <div>
                <Label className="text-sm">prefix</Label>
                <Input
                  className="mt-1.5"
                  placeholder=" 1A ? A"
                  value={houseNrExt}
                  onChange={(e) => setHouseNrExt(e.target.value)}
                />
              </div>
              {/* <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
                <div className="flex-1">
                  <Label className="text-sm">House Nr</Label>
                  <Input
                    className="mt-1.5"
                    placeholder="House Nr"
                    value={houseNr}
                    onChange={(e) => setHouseNr(e.target.value)}
                    required
                  />
                </div>
              </div> */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                <div>
                  <Label className="text-sm">City</Label>
                  <Input
                    className="mt-1.5"
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <Label className="text-sm">Country</Label>
                  <Select
                    className="mt-1.5"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  >
                    <option value="NL">Netherlands</option>
                    <option value="BE">Belgium</option>
                    <option value="GR">Germany</option>
                  </Select>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                <div>
                  <Label className="text-sm">State/Province</Label>
                  <Input
                    className="mt-1.5"
                    placeholder="State/Province"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <Label className="text-sm">Postal Code</Label>
                  <Input
                    className="mt-1.5"
                    placeholder="Postal Code"
                    value={postCode}
                    onChange={(e) => setPostCode(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div>
                <Label>Phone Number</Label>
                <div className="mt-1.5 flex">
                  <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 bg-neutral-50 text-neutral-500 text-sm">
                    <i className="text-2xl las la-phone-volume"></i>
                  </span>
                  <Input
                    className="!rounded-l-none"
                    placeholder="Phone Number"
                    value={phoneNr}
                    onChange={(e) => setPhoneNr(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="pt-2">
                <ButtonPrimary type="submit" disabled={loading}>Update Account</ButtonPrimary>
              </div>
            </div>
          </div>
        </form>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
