import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcModal from "shared/NcModal/NcModal";

export interface ProblemPlan {
  name: string;
  id: string;
  label: string;
}

export interface ModalReportItemProps {
  show: boolean;
  productName?:string
  onCloseModalReportItem: () => void;
  handleDeleteProduct:() => void;
}


const ModalDeleteProduct: FC<ModalReportItemProps> = ({
  show,
  onCloseModalReportItem,
  handleDeleteProduct,
  productName
}) => {

  const renderContent = () => {
    return (
      <form action="#">

        {/* TEXAREA MESSAGER */}
        <div className="mt-4 text-center">
          <h4 className="text-lg font-semibold text-neutral-700 dark:text-neutral-200">
            Are you sure you want to delete {productName}?
          </h4>
        </div>
        <div className="mt-4 space-x-3">
          <ButtonPrimary onClick={handleDeleteProduct} type="submit" className="bg-red-900">
            Delete
          </ButtonPrimary>
          <ButtonSecondary type="button" onClick={onCloseModalReportItem}>
            Cancel
          </ButtonSecondary>
        </div>
      </form>
    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={onCloseModalReportItem}
      contentExtraClass="max-w-screen-md"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle="Delete Product"
    />
  );
};

export default ModalDeleteProduct;
