import CheckoutApi from "Apis/CheckOutApi";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PageReturn = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState<string | null>(null);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    const fetchSessionStatus = async () => {
      const response = await CheckoutApi.getSessionStatus(sessionId);
      // console.log("rest", response);
      // console.log("session_id", sessionId);
      setStatus(response?.data);
    };
    if (sessionId) {
      fetchSessionStatus();
    }
  }, []);

  if (status === "open") {
    navigate("/checkout");
  }

  if (status === "complete") {
    return (
      <section id="success" style={styles.container}>
        <p style={styles.text}>
          We appreciate your business! A confirmation email will be sent to you.
          If you have any questions, please email{" "}
          <a href="mailto:info@oodblue.com">info@oodblue.com</a>.
        </p>
        <button
          style={styles.button}
          onClick={() => (window.location.href = "/account_orders")}
        >
          View orders
        </button>
      </section>
    );
  } else {
    return <p style={styles.errorText}>Something went wrong</p>;
  }
};

export default PageReturn;

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
  },
  text: {
    fontSize: "18px",
    margin: "20px 0",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#4CAF50",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  errorText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    fontSize: "18px",
    color: "red",
  },
};
