import axios from "axios";
import TokenManager from "./TokenManager";
import ApiKeyEnc from "./ApiKeyEnc";

const SWAGGER_CHECKOUT_URL =process.env.REACT_APP_SWAGGER_BASE_URL +"/Checkout";
const API_KEY= process.env.REACT_APP_API_KEY;
  
const CheckoutApi = {

  getSessionStatus: async (id) => {
    try {
      const encKey=await  ApiKeyEnc.encrypt(API_KEY)
      const response = await axios.get(SWAGGER_CHECKOUT_URL+"/"+id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  handleCheckOut: async (payload) => {
    try {
      const encKey=await  ApiKeyEnc.encrypt(API_KEY)
      const response = await axios.post(SWAGGER_CHECKOUT_URL,payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
};
export default CheckoutApi;
