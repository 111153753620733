import axios from "axios";
import TokenManager from "./TokenManager";
import ApiKeyEnc from "./ApiKeyEnc";

const SWAGGER_CATEGORIES_URL =
  process.env.REACT_APP_SWAGGER_BASE_URL + "/Categories";
const API_KEY = process.env.REACT_APP_API_KEY;

const CategoriesApi = {
  getAllCategories: async () => {
    try {
      const encKey = await ApiKeyEnc.encrypt(API_KEY);
      const response = await axios.get(SWAGGER_CATEGORIES_URL, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  getCategoryById: async (id) => {
    try {
      const encKey = await ApiKeyEnc.encrypt(API_KEY);
      const response = await axios.get(SWAGGER_CATEGORIES_URL + "/" + id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  addCategory: async (payload) => {
    try {
      const encKey = await ApiKeyEnc.encrypt(API_KEY);
      const response = await axios.post(SWAGGER_CATEGORIES_URL, payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  updateCategory: async (payload) => {
    try {
      const encKey = await ApiKeyEnc.encrypt(API_KEY);
      const response = await axios.patch(SWAGGER_CATEGORIES_URL, payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  deleteCategory: async (id) => {
    try {
      const encKey = await ApiKeyEnc.encrypt(API_KEY);
      const response = await axios.delete(SWAGGER_CATEGORIES_URL + "/" + id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
};
export default CategoriesApi;
