import { productImgs } from "contains/fakeData";
import productVariantImg2 from "images/products/v2.jpg";
import productVariantImg3 from "images/products/v3.jpg";
import productVariantImg4 from "images/products/v4.jpg";
import productVariantImg5 from "images/products/v5.jpg";
import productVariantImg6 from "images/products/v6.jpg";
//
import productSport1 from "images/products/product1.png";
import productSport2 from "images/products/product2.png";
import productSport3 from "images/products/product3.png";
import productSport4 from "images/products/product4.png";
import productSport5 from "images/products/product5.png";
import productSport6 from "images/products/product6.png";
import productSport7 from "images/products/product7.png";
import productSport8 from "images/products/product8.png";

//

export interface ProductVariant {
  id: number;
  name: string;
  thumbnail?: string; 
  color?: string;
  featuredImage: string;
}
export interface Product {
  id: number;
  name: string;
  price: number;
  image: string;
  description: string;
  category: string;
  tags: string; 
  variants?: ProductVariant[];
  variantType?: "color" | "image";
  sizes?: string[];
  allOfSizes?: string[];
  status?: "New in" | "limited edition" | "Sold Out" | "50% Discount";
}

const DEMO_VARIANTS: ProductVariant[] = [
  {
    id: 1,
    name: "Black",
    thumbnail: productVariantImg6,
    featuredImage: productImgs[0],
  },
  {
    id: 2,
    name: "White",
    thumbnail: productVariantImg2,
    featuredImage: productImgs[1],
  },
  {
    id: 3,
    name: "Orange",
    thumbnail: productVariantImg3,
    featuredImage: productImgs[2],
  },
  {
    id: 4,
    name: "Sky Blue",
    thumbnail: productVariantImg4,
    featuredImage: productImgs[3],
  },
  {
    id: 5,
    name: "Natural",
    thumbnail: productVariantImg5,
    featuredImage: productImgs[4],
  },
];
const DEMO_VARIANT_COLORS: ProductVariant[] = [
  {
    id: 1,
    name: "Violet",
    color: "bg-violet-400",
    featuredImage: productImgs[0],
  },
  {
    id: 2,
    name: "Yellow",
    color: "bg-yellow-400",
    featuredImage: productImgs[1],
  },
  {
    id: 3,
    name: "Orange",
    color: "bg-orange-400",
    featuredImage: productImgs[2],
  },
  {
    id: 4,
    name: "Sky Blue",
    color: "bg-sky-400",
    featuredImage: productImgs[3],
  },
  {
    id: 5,
    name: "Green",
    color: "bg-green-400",
    featuredImage: productImgs[4],
  },
];

// export const PRODUCTS: Product[] = [
//   {
//     id: 1,
//     name: "Rey Nylon Backpack",
//     description: "Brown cockroach wings",
//     price: 74,
//     image: productImgs[16],
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     // sizes: ["XS", "S", "M", "L", "XL"],
//     // allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
//     status: "New in",
//   },
//   {
//     id: 2,
//     name: 'Round Buckle 1" Belt',
//     description: "Classic green",
//     price: 68,
//     image: productImgs[1],
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     status: "50% Discount",
//   },
//   {
//     id: 3,
//     name: "Waffle Knit Beanie",
//     description: "New blue aqua",
//     price: 132,
//     image: productImgs[15],
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     // sizes: ["S", "M", "L", "XL"],
//     // allOfSizes: ["S", "M", "L", "XL", "2XL", "3XL"],
//   },
//   {
//     id: 4,
//     name: "Travel Pet Carrier",
//     description: "Dark pink 2023",
//     price: 28,
//     image: productImgs[3],
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     status: "Sold Out",
//   },
//   {
//     id: 5,
//     name: "Leather Gloves",
//     description: "Perfect mint green",
//     price: 42,
//     image: productImgs[4],
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     // sizes: ["XS", "S", "M", "L", "XL"],
//     // allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
//   },
//   {
//     id: 6,
//     name: "Hoodie Sweatshirt",
//     description: "New design 2023",
//     price: 30,
//     image: productImgs[5],
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variantType: "color",
//     variants: DEMO_VARIANT_COLORS,
//   },
//   {
//     id: 7,
//     name: "Wool Cashmere Jacket",
//     description: "Matte black",
//     price: 12,
//     image: productImgs[8],
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     status: "New in",
//   },
//   {
//     id: 8,
//     name: "Ella Leather Tote",
//     description: "Cream pink",
//     price: 145,
//     image: productImgs[7],
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     // sizes: ["XS", "S", "M", "L", "XL"],
//     // allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
//     status: "limited edition",
//   },
// ];

// export const SPORT_PRODUCTS: Product[] = [
//   {
//     id: 1,
//     name: "Mastermind Toys",
//     description: "Brown cockroach wings",
//     price: 74,
//     image: productSport1,
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     sizes: ["XS", "S", "M", "L", "XL"],
//     allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
//     status: "New in",
//   },
//   {
//     id: 2,
//     name: "Jump Rope Kids",
//     description: "Classic green",
//     price: 68,
//     image: productSport2,
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     status: "50% Discount",
//   },
//   {
//     id: 3,
//     name: "Tee Ball Beanie",
//     description: "New blue aqua",
//     price: 132,
//     image: productSport3,
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANTS,
//     variantType: "image",
//     sizes: ["S", "M", "L", "XL"],
//     allOfSizes: ["S", "M", "L", "XL", "2XL", "3XL"],
//   },
//   {
//     id: 4,
//     name: "Rubber Table Tennis",
//     description: "Dark pink 2023",
//     price: 28,
//     image: productSport4,
//     category: "Category 1", 
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     status: "Sold Out",
//   },
//   {
//     id: 5,
//     name: "Classic Blue Rugby",
//     description: "Perfect mint green",
//     price: 42,
//     image: productSport5,
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANTS,
//     variantType: "image",
//     sizes: ["XS", "S", "M", "L", "XL"],
//     allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
//   },
//   {
//     id: 6,
//     name: "Manhattan Toy WRT",
//     description: "New design 2023",
//     price: 30,
//     image: productSport6,
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variantType: "color",
//     variants: DEMO_VARIANT_COLORS,
//   },
//   {
//     id: 7,
//     name: "Tabletop Football ",
//     description: "Matte black",
//     price: 12, 
//     image: productSport7,
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     status: "New in",
//   },
//   {
//     id: 8,
//     name: "Pvc Catching Toy",
//     description: "Cream pink",
//     price: 145,
//     image: productSport8,
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     // sizes: ["XS", "S", "M", "L", "XL"],
//     // allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
//     status: "limited edition",
//   },
// ];

export const TOYS_PRODUCTS: Product[] = [
  {
    id: 1,
    name: "kinder Toys 1",
    description: "Brown cockroach wings",
    price: 24,
    image: productSport1,
    category: "84002758-aa04-4edd-ada1-0be293fa7c19",
    tags: "60d95f6f-44f5-4eb6-af3d-3f89528d0cfb",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    status: "New in",
  },
  {
    id: 2,
    name: "kinder Toys 2",
    description: "Classic green",
    price: 18,
    image: productSport2,
    category: "7ce2e6d7-fbd1-4660-b23e-0097ac0fdc67",
    tags: "80f3d560-7fca-40a7-9c4a-6e1af15ee002",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    status: "50% Discount",
  },
  {
    id: 3,
    name: "kinder Toys 3",
    description: "New blue aqua",
    price: 13,
    image: productSport3,
    category: "bd8a8146-ca02-446e-82f3-44f08a48f28b",
    tags: "3be9733e-f8ad-4bc6-8824-1ed680946dbf",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
  },
  {
    id: 4,
    name: "kinder Toys 4",
    description: "Dark pink 2023",
    price: 28,
    image: productSport4,
    category: "953b5dc9-07f7-4a02-bba7-68ffd77a4d4c",
    tags: "0e578a9a-6589-4c0b-a5d3-2f4986188332",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    status: "Sold Out",
  },
  {
    id: 5,
    name: "kinder Toys 5",
    description: "Perfect mint green",
    price: 12,
    image: productSport5,
    category: "d49baf97-b15b-46f7-8c28-c42f2ba934ed",
    tags: "0e578a9a-6589-4c0b-a5d3-2f4986188332",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
  },
  {
    id: 6,
    name: "kinder Toys 6",
    description: "New design 2024",
    price: 20,
    image: productSport6,
    category: "baa4c85d-4cc3-48b5-99d5-0b1e0c8c916c",
    tags: "9df67b62-2a33-4574-a80e-323751a71175",
    variantType: "color",
    variants: DEMO_VARIANT_COLORS,
  },
  {
    id: 7,
    name: "kinder Toys 7",
    description: "Matte black",
    price: 12, 
    image: productSport7,
    category: "7595558a-3d99-41c7-9991-2374523f981a",
    tags: "9df67b62-2a33-4574-a80e-323751a71175",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    status: "New in",
  },
  {
    id: 8,
    name: "kinder Toys 8",
    description: "Cream pink", 
    price: 15,
    image: productSport8,
    category: "8940e346-29b2-4c14-8c41-c3f1cee4ef4b",
    tags: "3be9733e-f8ad-4bc6-8824-1ed680946dbf",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    status: "limited edition",
  },
];
 