const encKey = process.env.REACT_APP_ENC_KEY;
async function getCryptoKey(encryptionKey) {
    // Convert the encryption key to Uint8Array
    const encoder = new TextEncoder();
    const keyData = encoder.encode(encryptionKey);
  
    // Hash the encryption key using SHA-256 to match C# method
    const hashedKey = await window.crypto.subtle.digest('SHA-256', keyData);
  
    // Import the hashed key into the Web Crypto API
    return await window.crypto.subtle.importKey(
      'raw',
      hashedKey, // Use the hashed key for AES
      { name: 'AES-CBC' },
      false,
      ['encrypt', 'decrypt']
    );
  }

const ApiKeyEnc = {

  encrypt: async (value) => {
    if (!value || !encKey) {
      throw new Error("Value and encryption key cannot be null or empty.");
    }
    // console.log("value",value)
    // Convert encryptionKey to CryptoKey format
    const key = await getCryptoKey(encKey);

    // Encode the value into a Uint8Array
    const encoder = new TextEncoder();
    const data = encoder.encode(value);

    // Generate a random initialization vector (IV)
    const iv = window.crypto.getRandomValues(new Uint8Array(16));

    // Perform the encryption using AES-CBC
    const encryptedData = await window.crypto.subtle.encrypt(
      {
        name: "AES-CBC",
        iv: iv, // Initialization Vector
      },
      key, // The CryptoKey
      data // The data to encrypt (as Uint8Array)
    );

    // Combine IV and encrypted data
    const encryptedArray = new Uint8Array(iv.length + encryptedData.byteLength);
    encryptedArray.set(iv); // Add IV at the beginning
    encryptedArray.set(new Uint8Array(encryptedData), iv.length); // Add encrypted data after the IV

    // Convert to base64 for easy transmission or storage
    return btoa(String.fromCharCode.apply(null, encryptedArray));
  },


};
export default ApiKeyEnc;