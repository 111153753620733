import CartsApi from 'Apis/CartsApi';
import React, { createContext, useState, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';

// Create the context
const CartContext = createContext<any>(undefined);

// Provide the context to children components
export const CartProvider = ({ children }:any) => {
  const [cart, setCart] = useState<any>([]);

  const addToCart =async (productId:any,variationId:any,quantity:number) => {
    // setCart((prevCart:any) => [...prevCart, product]);
    // setCart(product)
    const paylaod = {
      item: {
        productId: productId,
        variationId: variationId,
        quantity:quantity 
      },
    };
    const response = await CartsApi.addItem(paylaod);
    if (response.success) {
      setCart(response.data);
    } else {
      toast.error("could not add product");
    }
  };
  const removeFromCart =async (productId:any,variationId:any,quantity:number) => {
    const paylaod = {
      item: {
        productId: productId,
        variationId: variationId,
        quantity:quantity
      },
    };
    const response = await CartsApi.removeItem(paylaod);
    if (response.success) {
      setCart(response.data);
    } else {
      toast.error("could not remove product");
    }
  };

  const handleGetCart=async()=>{
      const response = await CartsApi.getCart()
      if(response.success){
        setCart(response.data)
      }
  }

  function formatNumber(value:any) {
    const  formattedValue = (value / 100).toFixed(2);
    return formattedValue;
}

  useEffect(()=>{
    handleGetCart()
  },[])
  return (
    <CartContext.Provider value={{ cart, addToCart,removeFromCart,setCart,handleGetCart,formatNumber }}>
      {children}
    </CartContext.Provider>
  );
};

// Hook to use the cart context
export const useCart = () => useContext(CartContext);
