import axios from "axios";
import TokenManager from "./TokenManager";
import ApiKeyEnc from "./ApiKeyEnc";


const SWAGGER_BASE_URL = process.env.REACT_APP_SWAGGER_BASE_URL + "/Users";
const API_KEY = process.env.REACT_APP_API_KEY;

const PasswordApi = {
  forgetPassword:async (payload) =>{
      try{
        const encKey=await  ApiKeyEnc.encrypt(API_KEY)
          const response = await axios.post(SWAGGER_BASE_URL +"/forgetpassword",payload,
            {
              headers: {
                Authorization: `Bearer ${TokenManager.getAccessToken()}`,
                "x-api-key": encKey,
              },
            }
          )
          return { success: true, data: response.data}
      } catch(error){
          return { success: false, error: error };
      }
    },
    resetPassword:async (payload) =>{
      try{
        const encKey=await  ApiKeyEnc.encrypt(API_KEY)
          const response = await axios.post(SWAGGER_BASE_URL +"/resetpassword",payload,
            {
              headers: {
                Authorization: `Bearer ${TokenManager.getAccessToken()}`,
                "x-api-key": encKey,
              },
            }
          )
          return { success: true, data: response.data}
      } catch(error){
          return { success: false, error: error };
      }
    },
    changePassword:async (payload) =>{
      try{
        const encKey=await  ApiKeyEnc.encrypt(API_KEY)
          const response = await axios.post(SWAGGER_BASE_URL +"/changepassword",payload,
            {
              headers: {
                Authorization: `Bearer ${TokenManager.getAccessToken()}`,
                "x-api-key": encKey,
              },
            }
          )
          return { success: true, data: response.data}
      } catch(error){
          return { success: false, error: error };
      }
    },
};
export default PasswordApi;
