import axios from "axios";
import TokenManager from "./TokenManager";
import ApiKeyEnc from "./ApiKeyEnc";


const SWAGGER_PRODUCTS_URL =process.env.REACT_APP_SWAGGER_BASE_URL + "/Products";
const API_KEY= process.env.REACT_APP_API_KEY;

const ProductsApi = {
  getAllProducts: async (token) => {
    try {
      const encKey=await  ApiKeyEnc.encrypt(API_KEY)
      const response = await axios.get(token ? SWAGGER_PRODUCTS_URL+"?ContinuationToken="+token:SWAGGER_PRODUCTS_URL, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  getProductById: async (id) => {
    try {
      const encKey=await  ApiKeyEnc.encrypt(API_KEY)
      const response = await axios.get(SWAGGER_PRODUCTS_URL+"/"+id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  addProduct: async (payload) => {
    try {
      const encKey=await  ApiKeyEnc.encrypt(API_KEY)
      const response = await axios.post(SWAGGER_PRODUCTS_URL,payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  updateProduct: async (payload) => {
    try {
      const encKey=await  ApiKeyEnc.encrypt(API_KEY)
      const response = await axios.patch(SWAGGER_PRODUCTS_URL,payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  deleteProduct: async (id) => {
    try {
      const encKey=await  ApiKeyEnc.encrypt(API_KEY)
      const response = await axios.delete(SWAGGER_PRODUCTS_URL+"/"+id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  addVariation: async (payload) => {
    try {
      const encKey=await  ApiKeyEnc.encrypt(API_KEY)
      const response = await axios.post(SWAGGER_PRODUCTS_URL+"/variations/add",payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  updateVariation: async (payload) => {
    try {
      const encKey=await  ApiKeyEnc.encrypt(API_KEY)
      const response = await axios.post(SWAGGER_PRODUCTS_URL+"/variations/update",payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  removeVariation: async (payload) => {
    try {
      const encKey=await  ApiKeyEnc.encrypt(API_KEY)
      const response = await axios.post(SWAGGER_PRODUCTS_URL+"/variations/update",payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
          "x-api-key": encKey,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
};
export default ProductsApi;
