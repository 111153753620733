import Prices from "components/Prices";
import { TOYS_PRODUCTS } from "data/data";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { useEffect, useState } from "react";
import OrdersApi from "Apis/OrdersApi";
import toast from "react-hot-toast";
import ProductsApi from "Apis/ProductsApi";
import InvoiceComponent from "components/InvoiceCompenent";
import { useCart } from "components/CartContext";
function formatDate(isoDate: string): string {
  const date = new Date(isoDate);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return date.toLocaleDateString("en-US", options);
}

const AccountOrder = () => {
  const [loading, setLoading] = useState(false);
  const [myOrders, setMyOrders] = useState<any>([]);
  const [conToken, setConToken] = useState();
  const { formatNumber } = useCart();

  const handleGetOrders = async () => {
    setLoading(true);
    const response = await OrdersApi.getAllOrders();
    if (response.success) {
      if (response.success) {
        setMyOrders(response.data?.orders);
        setConToken(response.data.continuationToken);
      }
    } else {
      toast.error("could not get orders");
    }
    setLoading(false);
  };

  const ToonMeer = async () => {
    const response = await OrdersApi.getAllOrders(conToken);
    if (response.success) {
      setMyOrders((prev:any) => [...prev, ...response.data.orders]);
      setConToken(response.data.continuationToken);
    }
  };

  // console.log(myOrders);
  useEffect(() => {
    handleGetOrders();
  }, []);

  const renderProductItem = (orderLine: any) => {
    const { variation, priceIncludingTax, quantity } = orderLine;
    const { images, name, attributes } = variation;
    return (
      <div
        key={variation?.id}
        className="flex py-4 sm:py-7 last:pb-0 first:pt-0"
      >
        <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={images && images[0]}
            alt={name}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium line-clamp-1">{name}</h3>
                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  <span>{attributes[0].value}</span>
                  {/* <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  <span>{"XL"}</span> */}
                </p>
              </div>
              <Prices
                className="mt-0.5 ml-2"
                price={formatNumber(priceIncludingTax)}
              />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400 flex items-center">
              <span className="hidden sm:inline-block">Qty</span>
              <span className="inline-block sm:hidden">x</span>
              <span className="ml-2">{quantity}</span>
            </p>

            {/* <div className="flex">
              <button
                type="button"
                className="font-medium text-indigo-600 dark:text-primary-500 "
              >
                Leave review
              </button>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const renderOrder = (order: any) => {
    return (
      <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
          <div>
            <p className="text-lg font-semibold flex mt-4 items-center">
              #{order?.id?.split("-")[0]}
              <Prices
                className="mt-0.5 ml-2 w-20"
                price={formatNumber(order?.totalIncludingTax)}
              />
            </p>

            <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
              <span>{formatDate(order?.orderDate)}</span>
              <span className="mx-2">·</span>
              <span className="text-primary-500">{order?.status}</span>
            </p>
            <p className="flex mt-4 justify-start"></p>
          </div>
          <div className="mt-3 sm:mt-0"></div>
          <InvoiceComponent order={order} />
        </div>
        <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
          {order?.orderLines?.map((orderLine: any) => {
            return renderProductItem(orderLine);
          })}
        </div>
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">Order History</h2>
          {loading && (
            <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
              ...Loading
            </p>
          )}
          {myOrders?.map((order: any) => {
            return renderOrder(order);
          })}
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountOrder;
