import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import toast from "react-hot-toast";
import PasswordApi from "Apis/PasswordApi";

export interface PageLoginProps {
  className?: string;
}
const PageForgetPass: FC<PageLoginProps> = ({ className = "" }) =>{
  const [loading,setLoading]=useState(false)
  const [email,setEmail]=useState<any>('') 

  const resetPassword=async()=>{

    if(email ==='' ){
      toast.error("please fill in email input")
      return
    }
    setLoading(true)
    const payload = {
          email:email
    }
    const response = await PasswordApi.forgetPassword(payload)
    if(response.success){
      toast.success("rest link was sent to your email")
      setEmail('')
    }else{
      toast.error("could not reset password")
    }
    setLoading(false)
}

    return (
        <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
          <Helmet>
            <title>Forget Password | Ood Blue</title>
          </Helmet>
          <div className="container mb-24 lg:mb-32">
            <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
              Reset Password
            </h2>
            <div className="max-w-md mx-auto space-y-6">
              <div className="grid gap-3">
              </div>
              {/* FORM */}
              <form className="grid grid-cols-1 gap-6"  >
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Email address
                  </span> 
                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1" 
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                  />
                </label>
                <ButtonPrimary  disabled={loading} onClick={resetPassword}>Rest</ButtonPrimary>
              </form>

            </div>
          </div>
        </div>
      );
}

export default PageForgetPass 
