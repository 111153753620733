import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import logo from '../images/logo.png';
import AuthApi from "Apis/AuthApi";
import TokenManager from "Apis/TokenManager";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useCart } from "./CartContext";

function formatDate(isoDate: string): string {
    const date = new Date(isoDate);
  
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
  
    return date.toLocaleDateString("en-US", options);
  }

const InvoiceComponent = ({order}:any) => {
    const user= TokenManager.getUserDetails()
    const {formatNumber}=useCart()
  const orderInfo = {
    invoiceNumber: order?.id?.split('-')[0],
    invoiceDate: formatDate(order?.orderDate),
    from: {
      name: "Ood Blue",
      address: "MontageWeg 35,", 
      city: "beverwijk, 1948 ph ",
      country: "Hal 5",
    },
    to: {
      name: user?.firstName + user?.middleName + user?.lastName,
      address: order?.shippingAddress.street,
      city:order?.shippingAddress.city +" "+ order?.shippingAddress.postCode,
      country: order?.shippingAddress.country , 
    },
    items: [
      {
        description: "Item 1",
        quantity: 1,
        rate: 0.0,
        amount: 10.00,
      },
      {
        description: "Item 2",
        quantity: 1,
        rate: 0.0,
        amount: 20.00,
      },
    ],
    notes: "It was great doing business with you.",
    terms: "www.oodblue.nl",
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    // Add logo image (assumes base64 format or URL)
    const imgData = logo; // Replace with base64 image data
    doc.addImage(imgData, 'PNG', 10, 10, 30, 30); // (imageData, format, x, y, width, height)

    // Add logo
    doc.setFontSize(18);
    doc.text("INVOICE", 160, 20);

    // Company Info
    doc.setFontSize(12);
    doc.text(orderInfo.from.name, 10, 45);
    doc.text(orderInfo.from.address, 10, 52);
    doc.text(`${orderInfo.from.city} ${orderInfo.from.country}`, 10, 58);

    // Client Info
    doc.setFontSize(12);
    doc.text("Bill To:", 10, 75);
    doc.text(orderInfo.to.name, 10, 83);
    doc.text(orderInfo.to.address, 10, 88);
    doc.text(`${orderInfo.to.city}, ${orderInfo.to.country}`, 10, 93);

    // Invoice Details
    doc.text(`Invoice#${orderInfo.invoiceNumber}`, 130, 75);
    doc.text(`Invoice Date: ${orderInfo.invoiceDate}`, 130, 80);

    // Table Headers
    doc.setFillColor(192, 192, 192); // Gray background
    doc.rect(10, 100, 190, 10, "F");
    doc.setFontSize(10);
    doc.setTextColor(255, 255, 255); // White text
    doc.text("Item Description", 12, 107);
    doc.text("Qty", 120, 107);
    doc.text("Discount", 140, 107);
    doc.text("Amount", 160, 107);

    // Table Rows
    let yPosition = 117;
    doc.setTextColor(0, 0, 0); // Black text

    order?.orderLines.forEach((item:any) => {
      doc.text(item.variation.name, 12, yPosition);
      doc.text("x"+item.quantity.toString(), 120, yPosition);
      doc.text("0.00", 140, yPosition);
      doc.text(formatNumber(item.priceExcludingTax), 160, yPosition);
      yPosition += 10;
    });

    // Footer - Notes
    doc.text("Notes", 10, yPosition + 115);
    doc.text(orderInfo.notes, 10, yPosition + 120);

    // Footer - Terms and Conditions
    doc.text("Terms & Conditions", 10, yPosition + 135);
    doc.text(orderInfo.terms, 10, yPosition + 140);

    // Calculate totals
    let subTotal = orderInfo.items.reduce((acc, item) => acc + item.amount, 0);
    let tax = subTotal * 0.1;
    let total = subTotal + tax;

    doc.text("Sub Total", 140, yPosition + 10);
    doc.text(formatNumber(order.totalExcludingTax), 180, yPosition + 10);
    doc.text("BTW Tax (21%)", 140, yPosition + 15);
    doc.text(formatNumber(order.totalTax), 180, yPosition + 15);
    doc.text("Shipping", 140, yPosition + 20);
    doc.text("5.00", 180, yPosition + 20);
    doc.text("TOTAL", 140, yPosition + 30);
    doc.text(`€ ${formatNumber(order.totalIncludingTax+500)}`, 160, yPosition + 30);

    doc.save("invoice.pdf");
  };

  return (
    <div>
      {/* <h1>Generate Invoice</h1> */}
      <ButtonSecondary
              sizeClass="py-2.5 px-4 sm:px-6"
              fontSize="text-sm font-medium"
              onClick={generatePDF}
            >
              Download Invoice
            </ButtonSecondary>

    </div>
  );
};

export default InvoiceComponent;
